body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'RedHatDisplay-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: inherit;
}

code {
  font-family: 'RedHatDisplay-Regular';
}

div,
h1,
h2,
h3,
a,
p,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'RedHatDisplay-Light';
  font-weight: 300;
  src: local('RedHatDisplay-Light'),
    url(../assets/fonts/RedHatDisplay-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-LightItalic';
  font-weight: 300;
  src: local('RedHatDisplay-LightItalic'),
    url(../assets/fonts/RedHatDisplay-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-Regular';
  font-weight: 400;
  src: local('RedHatDisplay-Regular'),
    url(../assets/fonts/RedHatDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-Italic';
  font-weight: 400;
  src: local('RedHatDisplay-Italic'),
    url(../assets/fonts/RedHatDisplay-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-Medium';
  font-weight: 500;
  src: local('RedHatDisplay-Medium'),
    url(../assets/fonts/RedHatDisplay-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-MediumItalic';
  font-weight: 500;
  src: local('RedHatDisplay-MediumItalic'),
    url(../assets/fonts/RedHatDisplay-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-SemiBold';
  font-weight: 600;
  src: local('RedHatDisplay-SemiBold'),
    url(../assets/fonts/RedHatDisplay-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-SemiBoldItalic';
  font-weight: 600;
  src: local('RedHatDisplay-SemiBoldItalic'),
    url(../assets/fonts/RedHatDisplay-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-Bold';
  font-weight: 700;
  src: local('RedHatDisplay-Bold'),
    url(../assets/fonts/RedHatDisplay-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-BoldItalic';
  font-weight: 700;
  src: local('RedHatDisplay-BoldItalic'),
    url(../assets/fonts/RedHatDisplay-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-ExtraBold';
  font-weight: 800;
  src: local('RedHatDisplay-ExtraBold'),
    url(../assets/fonts/RedHatDisplay-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'RedHatDisplay-ExtraBoldItalic';
  font-weight: 800;
  src: local('RedHatDisplay-ExtraBoldItalic'),
    url(../assets/fonts/RedHatDisplay-ExtraBoldItalic.ttf) format('truetype');
}

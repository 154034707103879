.DateRangeMainContainer button {
  font: inherit;
}

.react-calendar {
  width: 350px;
  background: white;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed; /* or absolute if you want it relative to a parent container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-calendar--doubleView {
  width: 650px;
}

.react-calendar__month-view {
  width: calc(40% - 1px);
}

.react-calendar__month-view:first-child {
  border-right: 1px solid #ccc;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: capitalize;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

/* Styling for the calendar navigation button icons */
.react-calendar__navigation__arrow {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-left: 5px;
  fill: #757575;
  cursor: pointer;
}

/* Styling for the calendar navigation month and year labels */
.react-calendar__navigation__label {
  font-size: 18px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: #666;
}
/* Styling for the range preview */
.react-calendar__tile--preview {
  background-color: #f5f5f5;
}

/* Styling for the range preview end */
.react-calendar__tile--previewEnd {
  background-color: #f5f5f5;
}

/* Styling for the range preview start */
.react-calendar__tile--previewStart {
  background-color: #f5f5f5; /* Match Material-UI hover color */
}

/* Styling for the clear button */
.react-daterange-picker__clear-button {
  color: #757575; /* Match Material-UI icon color */
  cursor: pointer;
  text-align: center;
  margin-right: 8px;
  border: 1px solid #000;
}

/* Styling for the clear button on hover */
.react-daterange-picker__clear-button:hover {
  color: #333; /* Match Material-UI text color */
}

/* Ensure that the input fields have a minimum width to prevent them from collapsing */
.react-daterange-picker__inputGroup {
  display: none;
}

.react-daterange-picker__calendarButton {
  display: none;
}

/* Ensure that the input fields have a minimum width to prevent them from collapsing */
.react-daterange-picker__inputGroup__input {
  flex-grow: 1;
  min-width: 150px;
  height: 30px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid #000;
  margin-left: 5px;
}

.react-daterange-picker__inputGroup__input:nth-child(2) {
  margin-bottom: 15px;
}

/* Styling for the calendar days */
.react-calendar__month-view__days__day {
  font-size: 14px;
  color: #333;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 30px;
  position: relative;
}

/* Styling for the circular border when hovered */
.react-calendar__month-view__days__day::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0);
  transition: border-color 0.3s ease;
  cursor: pointer;
}

.react-calendar__month-view__days__day,
.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year {
  margin-bottom: 10px;
}

/* Styling for the circular border when hovered */
.react-calendar__month-view__days__day:hover::before {
  background-color: transparent;
  border: 1px solid #000;
  color: #fff;
}

/* Styling for the hover effect on days */
.react-calendar__tile--hover {
  background-color: transparent;
  cursor: pointer;
}
.react-calendar__month-view__days__day:not(
    .react-calendar__month-view__days__day--neighboringMonth
  ):hover {
  background-color: transparent;
}
/* Styling for the selected date range background */
.react-calendar__month-view__days__day.react-calendar__tile--range {
  background-color: #c9b6db;
  color: #fff;
  border: none;
  height: 40px;
  position: relative;
}
.react-calendar__month-view__days__day.react-calendar__tile--range:hover {
  background-color: #c9b6db;
  color: #000;
  border: none;
  height: 40px;
  position: relative;
}

.react-calendar__month-view__week.react-calendar__tile--range {
  background-color: #c9b6db;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
}

.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}
